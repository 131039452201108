<template>
  <div>
    <h2 class="mb-3">Crystal Violet Hydroxylation: Results - Parts A & B</h2>

    <h3 class="mb-4">Part A</h3>

    <p class="mb-4">
      a) Fill in the table below using the data collected in Part A of the experiment.
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table dense>
        <thead>
          <tr>
            <td style="font-weight: bold">Property</td>
            <td style="font-weight: bold; text-align: center">Value</td>
          </tr>

          <tr>
            <td>Concentration of NaOH stock solution (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHStockA" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Volume of NaOH used in reaction (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volNaOHUsedA" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Concentration of CV+ stock solution (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.concCvStockA"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Volume of CV+ used in reaction (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volCvUsedA" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>[NaOH] at start of reaction (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHUsedA" :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </p>

    <p class="mb-2">
      b) Generate plots of [CV+] vs time, ln[CV+] vs time, and 1/[CV+] vs time using the software of
      your choice. Based on these plots, what is the order of the reaction in CV+? Justify your
      answer.
    </p>

    <s-textarea v-model="inputs.explainedOrderA" class="mb-4" outlined />

    <p class="mb-3">
      c) What is the effective rate constant, <stemble-latex content="$\text{k}_\text{eff},$" /> for
      the reaction at room temperature based on the conditions used in Part A of the experiment?
    </p>

    <calculation-input
      v-model="inputs.kEffA"
      class="mb-5"
      prepend-text="$\text{k}_\text{eff}:$"
      append-text="$\text{Units not provided}$"
      :disabled="!allowEditing"
    />

    <h3 class="mb-4">Part B</h3>

    <p class="mb-4">
      a) Fill in the table below using the data collected in Part B of this experiment.
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table dense>
        <thead>
          <tr>
            <td style="font-weight: bold">Property</td>
            <td style="font-weight: bold; text-align: center">Value</td>
          </tr>

          <tr>
            <td>Concentration of NaOH stock solution (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHStockA" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Volume of NaOH used in dilution (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volNaOHDilutionB" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Volume of H<sub>2</sub>O used in dilution (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volH2ODilutionB" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Concentration of diluted NaOH (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHDilutedB" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Volume of diluted NaOH used in reaction (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volNaOHUsedB" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Concentration of CV+ stock solution (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.concCvStockA"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Volume of CV+ used in reaction (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volCvUsedB" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>[NaOH] at start of reaction (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHUsedB" :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </p>

    <p class="mb-3">
      b) What is the effective rate constant, <stemble-latex content="$\text{k}_\text{eff},$" /> for
      the reaction at room temperature based on the conditions used in Part B of the experiment?
    </p>

    <calculation-input
      v-model="inputs.kEffB"
      class="mb-5"
      prepend-text="$\text{k}_\text{eff}:$"
      append-text="$\text{Units not provided}$"
      :disabled="!allowEditing"
    />

    <p class="mb-2">
      c) What is the order of the reaction in <chemical-latex content="OH^- ?" /> Explain how you
      determined this answer.
    </p>

    <s-textarea v-model="inputs.explainedOrderB" class="mb-4" outlined />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'CrystalViolet_ResultsAB',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        concNaOHStockA: null,
        volNaOHUsedA: null,
        concCvStockA: null,
        volCvUsedA: null,
        concNaOHUsedA: null,
        explainedOrderA: null,
        kEffA: null,

        volNaOHDilutionB: null,
        volH2ODilutionB: null,
        concNaOHDilutedB: null,
        volNaOHUsedB: null,
        volCvUsedB: null,
        concNaOHUsedB: null,
        kEffB: null,
        explainedOrderB: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
